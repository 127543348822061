import { css } from '@emotion/core';
import { Carousel, Icon } from 'antd';
import React, { useRef } from 'react';

import HeroSlide from './Components/HeroSlide';

const carouselConfig = {
  dots: true,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  swipeToSlide: true,
  draggable: true,
};

const style = {
  arrow: css`
    position: absolute;
    font-size: 40px;
    cursor: pointer;
    top: 45%;
    color: white;
    @media (max-width: 850px) {
      display: none;
    }
  `,
  arrowLeft: css`
    left: 20px;
    @media (min-width: 1400px) {
      left: calc(50% - 650px);
    }
  `,
  arrowRight: css`
    right: 20px;
    @media (min-width: 1400px) {
      right: calc(50% - 650px);
    }
  `,
};

const HeroSlides = ({ blockData, entities, homeHero }) => {
  const carouselRef = useRef();

  const components =
    blockData && blockData.Components && Array.isArray(blockData.Components)
      ? blockData.Components
      : [];

  const slides = components.filter(c => entities.Components[c] && entities.Components[c].ImageId);

  return (
    <React.Fragment>
      <Carousel ref={carouselRef} {...carouselConfig}>
        {slides.map(c => (
          <HeroSlide
            align={blockData.AlignPosition}
            homeHero={homeHero}
            componentData={entities.Components[c]}
            key={c}
          />
        ))}
      </Carousel>
      {slides.length > 1 && (
        <>
          <Icon
            type="left"
            css={[style.arrow, style.arrowLeft]}
            onClick={() => carouselRef.current.prev()}
          />
          <Icon
            type="right"
            css={[style.arrow, style.arrowRight]}
            onClick={() => carouselRef.current.next()}
          />
        </>
      )}
    </React.Fragment>
  );
};

export default HeroSlides;
