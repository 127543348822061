import { css, jsx } from '@emotion/core';
import Link from 'next/link';
import PropTypes from 'prop-types';
import React from 'react';

import btnStyle from '@/src/shared/components/Button/style';
import { bp } from '@/styles';

const getContentPos = align => {
  if (align === 'left') {
    return 'flex-start';
  }
  if (align === 'right') {
    return 'flex-end';
  }
  return 'center';
};

const style = {
  container: home => css`
    position: relative;
    z-index: 5;
    ${home
      ? css`
          background-image: linear-gradient(287.06deg, rgba(238, 238, 238, 0) 0%, #00407d 100%);
        `
      : css``}
  `,
  size: home => css`
    min-height: 375px;
    @media ${bp.md} {
      height: ${home ? '70vh' : '435px'};
    }
  `,
  slide: css`
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  `,
  contentContainer: css`
    font-family: 'Lato', sans-serif;
    width: 100%;
    color: white;
    position: absolute;
    top: 0;
    left: 0;
  `,
  content: position => css`
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: ${position};
  `,
  title: css`
    font-size: 3rem;
    line-height: 1.2;
    font-weight: 900;
    margin-bottom: 1.6rem;
    @media ${bp.md} {
      font-size: 4rem;
    }
    @media ${bp.lg} {
      font-size: 5rem;
    }
    @media ${bp.xl} {
      font-size: 6rem;
    }
  `,
  subTitle: css`
    font-size: 2rem;
    line-height: 1.2;
    font-weight: 800;
    margin-bottom: 3.2rem;
    @media ${bp.md} {
      font-size: 2.4rem;
    }
    @media ${bp.lg} {
      font-size: 3rem;
    }
    @media ${bp.xl} {
      font-size: 3.6rem;
    }
  `,
  link: css`
    height: auto;
  `,
};

const HeroSlide = ({ componentData, homeHero, align }) => {
  const {
    ImageId,
    Title,
    Subheading,
    ButtonText,
    ButtonUrl,
    AddButton,
    OpenInNewTab,
  } = componentData;
  const imageUrl = `${process.env.NEW_API_URL}image/file?area=c&id=${ImageId}`;
  const linkProp = OpenInNewTab === 'Y' ? { target: '_blank' } : {};

  // position should get from componentData after backend support
  const position = getContentPos(align);
  const sizeStyle = style.size(homeHero);

  return (
    <div css={style.container(homeHero)}>
      <div css={[style.contentContainer, sizeStyle]}>
        <div css={[style.content(position), sizeStyle]}>
          {Title && <div css={style.title}>{Title}</div>}
          {Subheading && <div css={style.subTitle}>{Subheading}</div>}
          {AddButton === 'Y' && (
            <Link href={ButtonUrl} passHref>
              <a
                css={[btnStyle.type.default, btnStyle.size.default, style.link]}
                href={ButtonUrl}
                {...linkProp}
              >
                {ButtonText}
              </a>
            </Link>
          )}
        </div>
      </div>
      <div css={[{ backgroundImage: `url(${imageUrl})` }, style.slide, sizeStyle]} />
    </div>
  );
};

HeroSlide.propTypes = {};

export default HeroSlide;
